import { getAdjustmentDate } from '../../../helpers/farm_profile';

export default {
  state: () => ({
    selectedCommodity: null,
    selectedTab: -1,
    cropModalOpen: false,
    // used to keep track of edits across all production rows
    globalEdited: false,
    isInventoryDialogOpen: false,
    recordToAddChildrenFor: null,
    recordToAdjustInventoryWith: null,
    adjustmentAmount: 1,
    newRecord: null,
    productionChanged: false,
    inventoryAdjustmentIsUpdate: true,
  }),
  getters: {
    selectedCommodity(state) {
      return state.selectedCommodity;
    },
    selectedTab(state) {
      return state.selectedTab;
    },
  },
  mutations: {
    setSelectedCommodity(state, commodity) {
      state.selectedCommodity = commodity;
    },
    setSelectedTab(state, tab) {
      state.selectedTab = tab;
    },
    setCropModalOpen(state, value) {
      state.cropModalOpen = value;
    },
    setGlobalEdited(state, val) {
      state.globalEdited = val;
    },
    openAdjustmentDialog(state, { recordToAdjust, amount }) {
      state.isInventoryDialogOpen = true;
      state.recordToAdjustInventoryWith = recordToAdjust;
      state.adjustmentAmount = amount;
    },
    closeAdjustmentDialog(state) {
      state.isInventoryDialogOpen = false;
      state.recordToAdjustInventoryWith = null;
      state.adjustmentAmount = 1;
    },
    setRecordToAddChildrenFor(state, selectedRecord) {
      state.recordToAddChildrenFor = selectedRecord;
    },
    setNewRecord(state, { newRecord, productionChanged }) {
      state.newRecord = newRecord;
      state.productionChanged = productionChanged;
    },
    setInventoryAdjustmentIsUpdate(state, value) {
      state.inventoryAdjustmentIsUpdate = value;
    },
  },
  actions: {
    openCropModal({ commit }) {
      commit('setCropModalOpen', true);
    },

    closeCropModal({ commit }) {
      commit('setCropModalOpen', false);
    },

    setGlobalEdited({ commit }, value) {
      commit('setGlobalEdited', value);
    },

    async updateAPRow({ rootState, dispatch }, { newRecord, productionChanged }) {
      const { selectedFarmLocations } = rootState.farmLocations;
      await dispatch('farmProfile/updateActualProduction', newRecord, { root: true });
      await dispatch('farmProfile/fetchActualProductions', {}, { root: true });
      if (productionChanged) {
        if (selectedFarmLocations) {
          await dispatch(
            'farmProfile/inventory/fetchBins',
            { locationIds: selectedFarmLocations },
            { root: true },
          );
        } else {
          this._vm.$snackbar.error('Please select any farm location.');
        }
      }
    },

    /**
     * To be called when an ActualProduction record is adjusted.
     *
     * When the ActualProduction record production value is changed:
     * 1. attempts to take the adjust the user's default (ungraded, unallocated)
     *    bin by the amount that the production value was changed.
     * 2. If it is not possible to adjust from the user's default bin, open the
     *    Inventory adjustment modal.
     *
     * @param oldRecord the original record
     * @param newRecord the edited record where some fields are changed
     * @param isUpdate whether the record update is the result of an update or a delete
     */
    async updateActualProductionRecord({ state, dispatch }, { oldRecord, newRecord, isUpdate = false }) {
      state.inventoryAdjustmentIsUpdate = isUpdate;
      try {
        let shouldUpdateProductionInventory = true;
        if (newRecord.production > oldRecord.production) {
          shouldUpdateProductionInventory = await dispatch('createIncreasingBinAdjustment', { oldRecord, newRecord });
        } else if (newRecord.production < oldRecord.production) {
          shouldUpdateProductionInventory = await dispatch('createDecreasingBinAdjustment', { oldRecord, newRecord });
        }

        // If not shouldUpdateProductionInventory, bin adjustment dialog is open and will handle updating
        if (shouldUpdateProductionInventory) {
          await dispatch('updateAPRow', { newRecord, productionChanged: oldRecord.production !== newRecord.production });
        }

        // TODO: TS-592
        // Currently editing seed holdback has no impact on inventory since it requires creating an adjustment which
        // in many cases will require the dialog of TS-592, increasing productions do not require this dialog but
        // I believe that this work should be scoped for TS-592 considering that it all ties together here in this function
        // I think we should try to mimic the existing behaviour laid out here (and potentially generalize the existing function)
      } catch (e) {
        this._vm.$snackbar.error('Failed to edit commodity');
      }
    },

    async createIncreasingBinAdjustment({ rootGetters }, { oldRecord, newRecord }) {
      // put it into ungraded unallocated bin
      const bin = rootGetters['farmProfile/inventory/getDefaultUngradedBinForCommodity'](newRecord.commodity_id);
      // if crop_year is previous, use previous crop_year at adjustmentDate.
      // otherwise(inner crop_year, future crop_year), use current time.
      const adjustmentDate = getAdjustmentDate(rootGetters['shared/cropYearById'](newRecord.crop_year_id));
      let result = false;
      if (bin != null) {
        await API.createBinAdjustment({
          source: null,
          destination: bin.id,
          quantity: newRecord.production - oldRecord.production,
          unit: newRecord.production_unit_id,
          comment: 'AP record adjustment (default).',
          adjustment_date: adjustmentDate.toISOString(),
        });
        result = true;
      } else {
        // entry into this branch indicates an invalid state
        // i.e the user tried to adjust a production, whose
        // commodity has no default grade.
        // this is invalid, since the server should've stopped them
        // from creating the production in the first place.
        this._vm.$snackbar.error('Failed to edit commodity');
      }

      return result;
    },

    async createDecreasingBinAdjustment({ commit, rootGetters }, { oldRecord, newRecord }) {
      const productionDiff = oldRecord.production - newRecord.production;
      // first see if we can remove from the unallocated ungraded bin
      const { result, bin } = rootGetters['farmProfile/inventory/canUseDefaultBin'](newRecord.commodity_id, productionDiff);
      // if crop_year is previous, use previous crop_year at adjustmentDate.
      // otherwise, use current time.
      const adjustmentDate = getAdjustmentDate(rootGetters['shared/cropYearById'](newRecord.crop_year_id));
      if (result) {
        await API.createBinAdjustment({
          source: bin.id,
          destination: null,
          quantity: productionDiff,
          unit: newRecord.production_unit_id,
          comment: 'AP record adjustment (default).',
          adjustment_date: adjustmentDate.toISOString(),
        });
      } else {
        // can't use the ungraded unallocated bin; show adjustment dialog
        commit('openAdjustmentDialog', {
          recordToAdjust: newRecord,
          amount: productionDiff,
        });
        // We have to set state so that InventoryModal can call updateAPRow
        // Look at handleAdjustment method in CommodityPickerInventoryModal.vue
        commit('setNewRecord', { newRecord, productionChanged: oldRecord.production !== newRecord.production });
      }

      return result;
    },
  },

  namespaced: true,
};
