const iconList = [
  {
    name: 'SmartAdvisor',
    import: () => import('@/assets/icons/feature-smart-advisor.svg'),
  },
  {
    name: 'ROICalculator',
    import: () => import('@/assets/icons/feature-roi-calculator.svg'),
  },
  {
    name: 'CashflowPlanner',
    import: () => import('@/assets/icons/feature-cashflow-planner.svg'),
  },
  {
    name: 'SeedHoldback',
    import: () => import('@/assets/icons/seed-holdback.svg'),
  },
];

const asyncComponents = {};
iconList.forEach((component) => {
  asyncComponents[component.name] = component.import;
});

export {
  iconList,
  asyncComponents,
};
