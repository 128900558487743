<template>
  <div>
    <v-btn
      v-if="showHelpButton"
      :block="fullWidth"
      :height="buttonHeight"
      :text="fullWidth"
      :tile="fullWidth"
      :class="widthClass"
      :loading="loading"
      :disabled="loading"
      target="_blank"
      x-large
      icon
      aria-label="Help"
      @click="viewDocumentDialogOpen = !viewDocumentDialogOpen"
    >
      <v-icon
        :class="[iconClass]"
      >
        mdi-help-circle
      </v-icon>
      <span
        v-if="fullWidth"
        class="reset--text"
        style="padding-left: 1px"
      >
        Help
      </span>
    </v-btn>
    <GDialog
      v-model="deleteConfirmationDialogOpen"
      title="Delete Help Document"
      :action-buttons="[
        {
          side: 'right',
          type: 'default',
          name: 'cancel',
          onClick: () => deleteConfirmationDialogOpen = false,
        },
        {
          side: 'right',
          type: 'delete',
          name: 'delete',
          onClick: deleteDocument,
        },
      ]"
    >
      Are you sure you want to delete this help document? This action is not reversible.
    </GDialog>
    <FileViewer
      ref="viewer"
      v-model="viewDocumentDialogOpen"
      :html="helpDocument"
      @close="viewDocumentDialogOpen = false"
      @edit="openEditor"
      @delete="deleteConfirmationDialogOpen = true"
    />
    <v-dialog
      v-model="editDocumentDialogOpen"
      persistent
      eager
      :retain-focus="false"
      fullscreen
      no-click-animation
      transition="dialog-bottom-transition"
    >
      <v-toolbar class="flex-grow-0">
        <v-spacer />

        <v-btn
          text
          color="primary"
          @click="saveConfirmationDialogOpen = true"
        >
          SAVE
        </v-btn>
        <GDialog
          v-model="saveConfirmationDialogOpen"
          title="Save Help Document"
          :action-buttons="[
            {
              side: 'right',
              type: 'default',
              name: 'cancel',
              onClick: () => saveConfirmationDialogOpen = false,
            },
            {
              side: 'right',
              type: 'primary',
              name: 'save',
              onClick: saveDocument,
            },
          ]"
        >
          Are you sure you want to modify this help document? The new contents will be seen by external users
        </GDialog>

        <v-btn
          icon
          @click="handleCloseEditor"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <GDialog
          v-model="closeConfirmationDialogOpen"
          title="Close Editor"
          :action-buttons="[
            {
              side: 'right',
              type: 'default',
              name: 'cancel',
              onClick: () => closeConfirmationDialogOpen = false,
            },
            {
              side: 'right',
              type: 'default',
              name: 'yes',
              onClick: closeEditor,
            },
          ]"
        >
          Are you sure you want to close the editor without saving your changes?
        </GDialog>
      </v-toolbar>

      <GenericEditor
        v-if="!loading"
        ref="editor"
        v-model="editedDocument"
        @input="updatedDocument = true"
      />
    </v-dialog>
  </div>
</template>

<script>
import GenericEditor from '@/components/generic/GenericEditor.vue';
import FileViewer from '@/components/generic/FileViewer.vue';
import GDialog from '@/components/generic/GDialog.vue';
import { mapState, mapGetters } from 'vuex';
import { postRequest, postFormRequest } from '@/helpers/request';

export default {
  name: 'HelpDocument',

  components: {
    FileViewer,
    GDialog,
    GenericEditor,
  },

  props: {
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      helpDocument: '',
      editedDocument: '',
      updatedDocument: false,

      helpDocumentURLKeys: [
        'farm_profile',
        'cash_flow_planner',
        'roi_calculator',
        'scenario_planner',
        'ai_advisor',
        'bids',
      ],

      saveConfirmationDialogOpen: false,
      deleteConfirmationDialogOpen: false,
      closeConfirmationDialogOpen: false,

      viewDocumentDialogOpen: false,
      editDocumentDialogOpen: false,

      loading: true,
      loadingPromise: null,
    };
  },

  computed: {
    ...mapState('launchDarkly', ['flags']),
    ...mapGetters('permissions', ['checkPermission']),

    helpDocumentKey() {
      return this.helpDocumentURLKeys.find((key) => window.location.pathname.includes(key));
    },

    showHelpButton() {
      if (!this.flags['show-help-button']) {
        return false;
      }

      if (this.checkPermission('css_module', 'read') && this.helpDocumentKey) {
        return true;
      }

      if (!this.helpDocument) {
        return false;
      }

      return true;
    },

    minWidth() {
      return this.$vuetify.breakpoint.xs ? '0' : '400px';
    },

    buttonHeight() {
      return this.fullWidth ? 56 : undefined;
    },

    widthClass() {
      if (this.fullWidth) {
        return 'full-width px-3';
      }
      return 'px-2';
    },

    iconClass() {
      return this.fullWidth ? 'v-list-item__icon ml-0 pl-1' : '';
    },
  },

  watch: {
    viewDocumentDialogOpen() {
      this.applyClickHandlersToDocument();
    },
  },

  async created() {
    await (this.loadingPromise = this.getRelevantHelpDocument());
    this.loadingPromise = null;
  },

  mounted() {
    if (this.loadingPromise) {
      this.loadingPromise.then(() => {
        this.checkQueryParams();
      });
    } else {
      this.checkQueryParams();
    }
  },

  methods: {
    checkQueryParams() {
      if (!this.showHelpButton) {
        return;
      }
      const urlParams = new URLSearchParams(window.location.search);
      const sectionId = urlParams.get('help_section_id');
      if (sectionId !== null) {
        this.viewDocumentDialogOpen = true;
        this.$nextTick(() => {
          // Timeout it to wait for transition to end
          const parentEl = this.$refs.viewer.$refs.content;
          const qs = `[data-help-document-id="${sectionId}"]`;
          const element = parentEl.querySelector(qs);
          if (element) {
            element.scrollIntoView({
              behavior: 'smooth',
            });
          }
        });
      }
    },

    applyClickHandlersToDocument() {
      const parentEl = this.$refs.viewer.$refs.content;
      const qs = '[data-help-document-id]';
      const snackbar = this.$snackbar;
      parentEl.querySelectorAll(qs).forEach((element) => {
        const url = new URL(window.location.href);
        url.searchParams.set('help_section_id', element.dataset.helpDocumentId);
        element.addEventListener('click', () => {
          navigator.clipboard.writeText(url.href);
          snackbar.success('Link copied to clipboard');
        });
      });
    },

    async getRelevantHelpDocument() {
      if (!this.helpDocumentKey) { return; }
      this.loading = true;
      try {
        const response = await postRequest('/library/api/get_help_document/', {
          help_document_id: this.helpDocumentKey,
        }, { responseType: 'text' });
        this.helpDocument = response;
        this.editedDocument = this.helpDocument;
      } catch (e) {
        this.$snackbar.error(`Failed to retrieve help document: ${e}`);
      } finally {
        this.loading = false;
      }
    },

    async saveDocument() {
      this.loading = true;
      let response;
      try {
        const formData = new FormData();
        formData.append('file', new File([this.editedDocument], { type: 'text/html;charset=utf-8' }));
        formData.append('help_document_id', this.helpDocumentKey);

        response = await postFormRequest('/library/api/update_help_document/', formData);

        await this.getRelevantHelpDocument();
        this.saveConfirmationDialogOpen = false;
        this.viewDocumentDialogOpen = true;
        this.editDocumentDialogOpen = false;
        this.updatedDocument = false;
      } catch (e) {
        this.$snackbar.error('Failed to save help document');
      } finally {
        this.loading = false;
      }
      this.$snackbar.success(response.message);
    },

    async deleteDocument() {
      this.loading = true;
      try {
        await postRequest('/library/api/delete_help_document/', {
          help_document_id: this.helpDocumentKey,
        });
        await this.getRelevantHelpDocument();
        this.updatedDocument = false;

        this.deleteConfirmationDialogOpen = false;
        this.viewDocumentDialogOpen = false;
        this.editDocumentDialogOpen = false;
      } catch (e) {
        this.$snackbar.error('Failed to delete help document');
      } finally {
        this.loading = false;
      }
    },

    handleInput(content) {
      this.updatedDocument = true;
      this.editedDocument = content;
    },

    openEditor() {
      this.viewDocumentDialogOpen = false;
      this.editDocumentDialogOpen = true;
    },

    closeEditor() {
      this.viewDocumentDialogOpen = true;

      this.editDocumentDialogOpen = false;
      this.saveConfirmationDialogOpen = false;
      this.closeConfirmationDialogOpen = false;

      this.editedDocument = this.helpDocument;
      this.updatedDocument = false;
      this.$refs.editor.setContent(this.helpDocument);
    },

    handleCloseEditor() {
      if (this.updatedDocument) {
        this.closeConfirmationDialogOpen = true;
      } else {
        this.closeEditor();
      }
    },

  },
};
</script>
<style lang="scss">
[data-help-document-id]:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>
