import { postRequest } from '../../../helpers/request';
import { approxEqual } from '../../../helpers/approximations';

async function fetchAllGrades() {
  const res = await API.getGrades();
  return res;
}

async function fetchAllBinStorageClassifications() {
  const res = await API.fetchAllBinStorageClassifications();
  return res;
}

async function fetchAllGrainBinTypes() {
  const res = await API.fetchAllGrainBinTypes();
  return res;
}

async function fetchAllBinGroupTypes() {
  const res = await API.fetchAllBinGroupTypes();
  return res;
}

export default {
  state: () => ({
    context: 'Inventory',
    binGroups: {},
    emptyBinGroups: {},
    bins: {},
    grades: {},
    adjustReasons: ['Consolidation', 'Spoilage', 'Spoilage Prevention', 'Freeing up Space', 'Permanent to Temporary Storage', 'Temporary to Permanent Storage', 'Blending for Grade/Moisture Reasons', 'Underestimated', 'Overestimated', 'Other'],
    holdbackReasons: ['Seeded for crop', 'Damaged Seeds', 'Contaminated Seeds'],
    grainBinTypes: {},
    binStorageClassification: {},
    binGroupTypes: {},
    storageDialogOpen: false,

    /** @type {('create'|'update'|'delete')} */
    storageGroupDialogMode: 'create',

    /** @type {boolean} */
    storageGroupDialogOpen: false,

    /** @type {number} */
    storageGroupDialogId: 0,
    storageAdded: [],
    isStorageCardOpen: [],
    currentStorageCard: null,
    unsavedChanges: {},
  }),

  getters: {
    /**
     * Converts a given bin's filled/capacity to a specific unit.
     * This does NOT make any changes in the backend,
     * nor does it make any changes to the bin object provided.
     * This should be used only to display values to the user.
     * @param bin the bin object to convert
     * @param toUnit the unit object to convert to
     * @returns an object: {filled: x, capacity: x} representing the
     *         converted values.
     */
    getConvertedBin: (state, getters, rootState, rootGetters) => (bin, toUnit) => {
      let data = null;
      if (bin.commodity_id !== null) {
        const fromUnit = bin.bin_unit;
        const filled = rootGetters['shared/convert'](bin.filled, fromUnit, toUnit, bin.commodity_id);
        const capacity = rootGetters['shared/convert'](bin.capacity, fromUnit, toUnit, bin.commodity_id);
        data = { filled, capacity };
      }
      return data;
    },

    /**
     * Filters the bins dict down to a list of bins with the given
     * commodity
     * @param commodityId the id of the commodity to filter for
     * @returns a list of bins containing the commodity provided
     */
    getBinsWithCommodity: (state) => (commodityId) => {
      if (commodityId != null) {
        return Object.entries(state.bins)
          .filter(([, bin]) => bin.commodity_id
            === commodityId).map(([, bin]) => bin);
      }
      return [];
    },

    /**
     * Filters the bins dict down to bins containing a commodity
     * in the provided list.
     * @param commodityIds the list of commodity ids to filter bins for
     * @returns a dict {key: id, value: bin} of bins containing one of the the commodities provided
     */
    getBinsWithCommodityIdsAsDict: (state) => (commodityIds) => {
      if (commodityIds && commodityIds.length > 0) {
        return Object.fromEntries(Object.entries(state.bins)
          .filter(([, bin]) => commodityIds.includes(bin.commodity_id)));
      }
      return [];
    },

    /**
     * Filters the bins dict down to bins containing a commodity
     * in the provided list, or bins without a commodity
     * @param commodityIds the list of commodity ids to filter bins for
     * @returns a dict {key: id, value: bin}
     *          of bins containing one of the the commodities provided, or no commodity
     */
    getBinsWithNullCommodity: (state) => (commodityIds) => {
      if (commodityIds && commodityIds.length > 0) {
        return Object.fromEntries(Object.entries(state.bins)
          .filter(([, bin]) => bin.commodity_id === null
            || commodityIds.includes(bin.commodity_id)));
      }
      return [];
    },

    /**
     * Attempts to find the default (ungraded) grade for the given commodity
     * @param commodityId the id of the commodity to search for
     * @returns the grade object, if found, null otherwise.
     */
    getDefaultGradeForCommodity: (state) => (commodityID) => {
      const defaultGradeRank = -1;
      const data = Object.values(state.grades)
        .find((grade) => grade.commodity_id === commodityID && grade.rank === defaultGradeRank);

      return data;
    },

    /**
     * Attempts to find the seed holdback grade for the given commodity
     * @param commodityID the id of the commodity to search for
     * @returns the grade object, if found, null otherwise.
     */
    getSeedHoldbackGradeForCommodity: (state) => (commodityID) => {
      const holdbackRank = -2;
      const data = Object.values(state.grades)
        .find((grade) => grade.commodity_id === commodityID && grade.rank === holdbackRank);

      return data;
    },

    /**
     * Attempts to find the unallocated bin for the given grade
     * @param gradeId the id of the grade to search for
     * @returns the grade object if found, null otherwise.
     */
    getUnallocBinForGrade: (state) => (gradeId) => {
      const data = Object.entries(state.bins)
        .filter(([, bin]) => bin.grade_id === gradeId && bin.is_unallocated)
        .map(([, value]) => value);
      if (data.length > 0) {
        return data[0];
      }
      return null;
    },

    /**
     * Attempts to find the default, ungraded, unallocated bin for a given commodity.
     * @param commodity the commodity object to find the bin for
     * @returns a bin object if found, null otherwise.
     */
    getDefaultUngradedBinForCommodity: (state, getters) => (commodity) => {
      const defaultGrade = getters.getDefaultGradeForCommodity(commodity)?.id;
      if (!defaultGrade) {
        return null;
      }
      return getters.getUnallocBinForGrade(defaultGrade);
    },

    /**
     * Attempts to find, then check if the default ungraded, unallocated bin
     * for the given commodity can be used to remove the given quantity
     * @param commodity the commodity object to find the bin for
     * @param quantity the quantity to check for
     * @returns {result: true, bin: default bin object} if the default
     *          bin has at least filled >= quantity, otherwise, returns
     *          {result: false, bin: null}
     */
    canUseDefaultBin: (state, getters) => (commodity, quantity) => {
      const bin = getters.getDefaultUngradedBinForCommodity(commodity);
      if (bin != null) {
        if (bin.filled >= quantity) {
          return {
            result: true,
            bin,
          };
        }
      }
      return {
        result: false,
        bin: null,
      };
    },

    /**
     * Filters Grades for a given commodity ID.
     * @param commodityId the commodity id to filter for
     * @returns Object of filtered grades (keys are grade Id)
     *    {
     *      99:
     *        {
     *          id: 99,
     *          commodity_id: 113,
     *          rank: 1,
     *          name: "#1 Wheat CWRS",
     *        }
     *    }
     */
    filterGradesByCommodity: (state) => (commodityId) => {
      const filteredGrades = Object.keys(state.grades).reduce((filtered, gradeId) => {
        if (state.grades[gradeId].commodity_id === commodityId) {
          return {
            ...filtered,
            [gradeId]: { ...state.grades[gradeId] },
          };
        }
        return filtered;
      }, {});
      return filteredGrades;
    },

    /**
     * This is needed to work around recursive Actual Productions.
     * If, ever in the future this structure is changed, this function can
     * go as well.
     *
     * Short form:
     * Takes in leaf commodities, and a cropyears AP records,
     * and figures out which split will need inventory adjustments.
     * (the first split of a parent).
     *
     * Long Form:
     * takes the filtered commodities to add (meaning only
     * relative leaf commodities in the list), looking at those
     * that have parents that already have AP records for this cropyear
     * essentially this means adding a split.
     * we also ensure that the already existing AP record has no children
     *
     * of the selected potential splits for an AP in the selected CropYear,
     * take the first (this is what the backend does)
     * and return the parent ap, the split ap, and the production unit/amount
     * for each.
     *
     * @param filteredCommodities relative leaf commodities in the overall list of commodities
     * @param actualProductions all ActualProductions for the cropyear
     */
    getAdjustmentsForCommodityList: (state, getters, rootState) => (filteredCommodities, actualProductions) => {
      const adjustments = [];
      const parents = [];
      const apParents = actualProductions.reduce((obj, ap) => (
        { ...obj, [ap.commodity_id]: ap }
      ), {});
      filteredCommodities.forEach((commodId) => {
        let curr = rootState.shared.commodities[commodId];
        while (curr != null && curr.parent_id != null) {
          if (Object.prototype.hasOwnProperty.call(apParents, curr.parent_id)) {
            if (!parents.includes(curr.parent_id)) {
              const ap = apParents[curr.parent_id];
              if (!getters.apHasChildren(ap.id, actualProductions)
                && !approxEqual(ap.production, 0)) {
                parents.push(curr.parent_id);
                adjustments.push({
                  parentId: curr.parent_id,
                  descendantId: commodId,
                  production: ap.production,
                  productionUnit: ap.production_unit_id,
                });
              }
            }
            break;
          } else {
            curr = rootState.shared.commodities[curr.parent_id];
          }
        }
      });
      return adjustments;
    },

    /**
     * @returns true if the ActualProduction has children records, false otherwise.
     */
    apHasChildren: () => (parentApId, apList) => apList.some(
      (ap) => ap.parent_id === parentApId,
    ),

    /**
     * @returns an array of binGroup objects that the currently selected farms have
     */
    binGroupsRegistered(state, getters, rootState) {
      const { selectedFarmLocations } = rootState.farmLocations;
      const binGroupList = Object.values(state.binGroups)
        .filter((binGroup) => selectedFarmLocations
          .includes(binGroup.farm_location_id));
      return binGroupList;
    },

    /**
     * @returns an array of binGroup objects that the currently selected farms has,
     * including its child storages
     */
    binGroupsWithStorages(state, getters) {
      return getters.binGroupsRegistered.reduce((a, b) => ({
        ...a,
        [b.id]: Object.values(state.bins).filter((bin) => bin.bin_group_id === b.id),
      }), {});
    },

    getStorageGroupById: (state) => (storageGroupId) => state.binGroups[storageGroupId] || null,

    hasStorages: (state, getters) => (storageGroupId) => getters
      .binGroupsWithStorages[storageGroupId]?.length > 0,
  },

  mutations: {
    setGrades(state, payload) {
      state.grades = payload;
    },
    setBinStorageClassifications(state, payload) {
      state.binStorageClassification = payload;
    },
    setGrainBinTypes(state, payload) {
      state.grainBinTypes = payload;
    },
    setBinGroupTypes(state, payload) {
      state.binGroupTypes = payload;
    },
    setBinGroups(state, binGroups) {
      state.binGroups = binGroups;
    },
    setEmptyBinGroups(state, binGroups) {
      state.emptyBinGroups = binGroups;
    },
    setBins(state, bins) {
      state.bins = bins;
    },
    setStorageGroupDialogOpen(state, value) {
      state.storageGroupDialogOpen = value;
    },
    setStorageDialogOpen(state, value) {
      state.storageDialogOpen = value;
    },
    setIsStorageCardOpen(state, value) {
      state.isStorageCardOpen = value;
    },
    setUnsavedChanges(state, payload) {
      if (Object.keys(payload).length > 0) {
        const { key, value } = payload;
        state.unsavedChanges[key] = value;
        return;
      }
      state.unsavedChanges = {};
    },
    setCurrentStorageCard(state, value) {
      state.currentStorageCard = value;
    },

    /**
     *  Converts the state's unallocated bins capacity/filled to the selected unit according
     *  to the user's UnitPreferences.
     *  Allocated bins remain untouched.
     */
    initialConversion(state, {
      convert, currentUnitPreference,
    }) {
      Object.keys(state.bins).forEach((id) => {
        // Since inventory uses the same units as production and commodities maintain their selected
        // units throughout the farm profile, we simply use whatever's stored in production
        // preferences
        const bin = state.bins[id];
        // Now only for unallocated bins! *wow*
        if (bin.commodity_id !== null && bin.is_unallocated) {
          const fromUnit = bin.bin_unit;
          const toUnit = currentUnitPreference('Production');
          const newCapacity = convert(bin.capacity, fromUnit, toUnit, bin.commodity_id);
          bin.capacity = newCapacity;
          const newFilled = convert(bin.filled, fromUnit, toUnit, bin.commodity_id);
          bin.filled = newFilled;
          bin.bin_unit = toUnit.id;
        }
      });
    },

    setStorageGroupDialogId(state, id) {
      state.storageGroupDialogId = id;
    },

    setStorageGroupDialogMode(state, mode) {
      state.storageGroupDialogMode = mode;
    },

    setStorageAdded(state, value) {
      state.storageAdded = value;
    },
  },

  actions: {
    openStorageDialog({ commit }) {
      commit('setStorageDialogOpen', true);
    },
    closeStorageDialog({ commit }) {
      commit('setStorageDialogOpen', false);
    },
    /**
     * Calls fetchBins with all selectedFarmLocations
     */
    async fetchBinsWithSelectedLocations({ dispatch, rootState }) {
      const { selectedFarmLocations } = rootState.farmLocations;
      await dispatch('fetchBins', { locationIds: selectedFarmLocations });
    },
    /**
     * Fetches all the bins from the backend with one of the given locations
     * @param locationIds the list of locations to search for
     */
    async fetchBins(
      { commit, rootState, rootGetters },
      { locationIds },
    ) {
      // TODO: UT-854 what is the benefit of these three separate requests?
      await Promise.all([
        postRequest(
          '/farm_profile/api/get_subscriptions_bin_groups',
        ),
        postRequest(
          '/farm_profile/api/get_subscriptions_bins',
          { location_ids: locationIds },
        ),
        postRequest(
          '/farm_profile/api/get_subscriptions_empty_bin_groups',
        ),
      ]).then((data) => {
        commit('setBinGroups', data[0]);
        commit('setBins', data[1]);
        commit('setEmptyBinGroups', data[2]);
        commit('initialConversion', {
          convert: rootGetters['shared/convert'],
          currentUnitPreference: rootGetters['farmProfile/currentUnitPreference'],
          standardUnit: rootState.shared.kgUnit,
        });
      });
      return Promise.resolve();
    },

    /**
     * Fetches the list of all grades from the backend
     */
    async fetchGrades({ commit }) {
      const data = await fetchAllGrades();
      commit('setGrades', data);
    },

    async fetchBinStorageClassifications({ commit }) {
      const data = await fetchAllBinStorageClassifications();
      commit('setBinStorageClassifications', data);
    },

    async fetchGrainBinTypes({ commit }) {
      const data = await fetchAllGrainBinTypes();
      commit('setGrainBinTypes', data);
    },

    async fetchBinGroupTypes({ commit }) {
      const data = await fetchAllBinGroupTypes();
      commit('setBinGroupTypes', data);
    },

    /**
     * Creates a collection of BinAdjustments.
     *
     * Each index in each list corresponds to a different
     * bin adjustment.
     * I.e sources[1] is for the second bin adjustment
     * and quantities[1] is the quantity for said bin adjustment
     * @param sources a list of sources (or null)
     * @param destinations a list of destinations (or null)
     * @param quantities a list of quantities to move
     * @param unit the unit to make the adjustments in (or a list of units)
     * @param comment a general comment that will be added for each adjustment
     * @param adjustmentDate when customer use previous crop_year, this field needs
     */
    async createManyBinAdjustments(context, {
      sources, destinations, quantities, unit, comment, adjustmentDate = null,
    }) {
      await postRequest(
        '/farm_profile/api/create_many_bin_adjustments/',
        {
          sources,
          destinations,
          quantities,
          unit,
          comment,
          adjustment_date: adjustmentDate,
        },
      );
    },

    /**
     * Creates a storage group (bin group)
     * @param binGroupName a string that is the name of the new bin group
     * @param farmLocationId the id of the farm location that this new group is for
     * @param storageGroupType the type of storage: {1: bin yard, 2: grain elevator}
     */
    async createStorageGroup({ state, commit }, {
      binGroupName, farmLocationId, storageGroupType,
    }) {
      try {
        const res = await API.createStorageGroup({
          binGroupName, farmLocationId, storageGroupType,
        });
        this._vm.$snackbar.success(res.message);

        const copyBinGroups = { ...state.binGroups, [res.data.id]: res.data };
        commit('setBinGroups', copyBinGroups);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async updateStorageGroup({ state, commit }, {
      id, binGroupName, farmLocationId, storageGroupType,
    }) {
      try {
        const res = await API.updateStorageGroup({
          id, binGroupName, farmLocationId, storageGroupType,
        });
        this._vm.$snackbar.success(res.message);

        const copyBinGroups = { ...state.binGroups, [id]: res.data };
        commit('setBinGroups', copyBinGroups);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async deleteStorageGroup({ commit, state }, { id }) {
      try {
        const res = await API.deleteStorageGroup({ id });
        this._vm.$snackbar.success(res.message);

        const copyBinGroups = { ...state.binGroups };
        delete copyBinGroups[id];
        commit('setBinGroups', copyBinGroups);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    /**
     *
     * @param name name of the bin that is to be created
     * @param capacity capacity of the bin that is going to be created
     * @param filled amount of the bin that is filled
     * @param isUnallocated is an unalloactedBin
     * @param binUnit the id of the bin unit
     * @param storageGroup the parent storageGroup of the bin
     * @param binStorageClassificationId the classification of the bin
     * @param isFertilised if the bin is fertilised (restricted to specific
     *          bin storage classifications, should be enforced in front-end)
     */
    async createStorage({ commit, state }, {
      name, capacity, filled, isUnallocated, binUnit, storageGroup,
      binStorageClassificationId, isFertilised,
    }) {
      try {
        const result = await API.createStorage({
          name,
          capacity,
          filled,
          isUnallocated,
          binUnit,
          storageGroup,
          binStorageClassificationId,
          isFertilised,
        });
        commit('setStorageAdded', [...state.storageAdded, result.bin]);
        this._vm.$snackbar.success(result.message);
        return result?.bin?.id;
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
      return null;
    },

    /**
     *
     * @param name name of the bin that is to be created
     * @param capacity capacity of the bin that is going to be created
     * @param filled amount of the bin that is filled
     * @param isUnallocated is an unalloactedBin
     * @param binUnit the id of the bin unit
     * @param gradeId id of the grade of the bin
     * @param storageGroup the parent storageGroup of the bin
     * @param binStorageClassificationId the classification of the bin
     * @param isFertilised if the bin is fertilised (restricted to specific
     *          bin storage classifications, should be enforced in front-end)
     * @param moisture the moisture of the bin
     * @param temperature the temperature of the bin
     * @param temperatureUnitId the unit of the temperature
     * @param notes the notes of the bin
     */
    async editStorage(context, {
      binId, name, capacity, filled, binUnit, gradeId,
      storageGroup, binStorageClassificationId, isFertilised, moisture, temperature, temperatureUnitId, notes,
    }) {
      try {
        const res = await API.editStorage({
          binId,
          name,
          capacity,
          filled,
          binUnit,
          gradeId,
          storageGroup,
          binStorageClassificationId,
          isFertilised,
          moisture,
          temperature,
          temperature_unit: temperatureUnitId,
          notes,
        });
        this._vm.$snackbar.success(res.message);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    openStorageGroupDialog({ commit }, val) {
      commit('setStorageGroupDialogId', 0);
      commit('setStorageGroupDialogMode', 'create');
      commit('setStorageGroupDialogOpen', true);

      if (typeof (val) === 'number') {
        commit('setStorageGroupDialogId', val);
        commit('setStorageGroupDialogMode', 'update');
      } else {
        commit('setStorageGroupDialogId', val?.id || null);
        commit('setStorageGroupDialogMode', val?.mode || 'create');
      }
    },

    closeStorageGroupDialog({ commit }) {
      commit('setStorageGroupDialogOpen', false);
    },
  },

  namespaced: true,
};
