export default [
  { title: 'Dashboard', path: '/dashboard/', icon: 'mdi-view-dashboard' },

  {
    title: 'Resource Hub',
    path: '/dashboard/resource_hub/',
    icon: 'mdi-file-document-multiple',
    permission: {
      scope: 'resource_hub',
      permission: 'read',
    },
  },
  {
    title: 'Market Insights',
    path: '/insights/overview/',
    icon: 'mdi-eye-circle-outline',
    permission: {
      scope: 'powerbi_insights',
      permission: 'read',
    },
    showCommercialOnly: true,
    allowBadPayments: true,
  },
  {
    isParent: true,
    title: 'Farm Profile',
    icon: 'mdi-finance',
    stagingOnly: false,
    showCommercial: false,
  },
  {
    parent: 'Farm Profile',
    title: 'Farms',
    path: '/farm_profile/farms/',
    routerPath: true,
    stagingOnly: false,
    permission: {
      scope: 'farm_profile',
      permission: 'read',
    },
    allowBadPayments: true,
  },
  {
    parent: 'Farm Profile',
    title: 'Commodities',
    path: '/farm_profile/commodities/',
    routerPath: true,
    stagingOnly: false,
    permission: {
      scope: 'farm_profile',
      permission: 'read',
    },
    allowBadPayments: true,
  },
  {
    parent: 'Farm Profile',
    title: 'Inventory',
    path: '/farm_profile/inventory/',
    routerPath: true,
    stagingOnly: false,
    permission: {
      scope: 'farm_profile',
      permission: 'read',
    },
    allowBadPayments: true,
  },
  {
    parent: 'Farm Profile',
    title: 'Sales',
    path: '/farm_profile/sales/',
    routerPath: true,
    stagingOnly: false,
    permission: {
      scope: 'farm_profile',
      permission: 'read',
    },
    allowBadPayments: true,
  },
  {
    parent: 'Farm Profile',
    title: 'Reports',
    path: '/farm_profile/reports/',
    routerPath: true,
    stagingOnly: false,
    permission: {
      scope: 'farm_profile',
      permission: 'read',
    },
    allowBadPayments: true,
  },
  {
    title: 'Barchart Overview',
    path: '/dashboard/barchart/overview/',
    icon: 'mdi-chart-line',
  },
  {
    title: 'Smart Advisor',
    path: '/budgeting/ai_advisor/',
    icon: '$vuetify.icons.smartAdvisor',
    stagingOnly: false,
    permission: {
      scope: 'AI_Advisor',
      permission: 'read',
    },
  },
  {
    title: 'News',
    path: '/news/',
    icon: 'mdi-newspaper-variant-multiple',
    routerPath: false,
  },
  {
    isParent: true,
    title: 'Budgeting',
    icon: 'mdi-chart-histogram',
    stagingOnly: false,
    showCommercial: false,
  },
  {
    parent: 'Budgeting',
    title: 'ROI Calculator',
    path: '/budgeting/roi_calculator/',
    routerPath: true,
    stagingOnly: false,
    permission: { scope: 'ROI_calculator', permission: 'read' },
    allowBadPayments: true,
  },
  {
    parent: 'Budgeting',
    title: 'Cash Flow Planner',
    path: '/budgeting/cash_flow_planner/',
    permission: { scope: 'cash_flow_planner', permission: 'read' },
    allowBadPayments: true,
    routerPath: true,
    stagingOnly: false,
    farmerOnly: false,
  },

  {
    parent: 'Budgeting',
    title: 'Risk Profile',
    path: '/budgeting/risk_profile/',
    stagingOnly: false,
    isOwner: true,
    permission: {
      scope: 'risk_profile',
      permission: 'read',
    },
    allowBadPayments: true,
    isUS: false,
  },
  {
    parent: 'Budgeting',
    title: 'Scenario Planner',
    path: '/budgeting/scenario_planner/',
    stagingOnly: false,
    ldFlagKey: 'scenario-planner',
    permission: {
      scope: 'scenario_planner',
      permission: 'read',
    },
    allowBadPayments: true,
  },

  {
    parent: 'Budgeting',
    title: 'GMA Risk Appraisals',
    path: '/budgeting/risk_profile/',
    stagingOnly: false,
    isSubscriptionGMA: true,
    permission: {
      scope: 'risk_profile',
      permission: 'read',
    },
    allowBadPayments: true,
  },
  {
    isParent: true,
    title: 'Price Finder',
    icon: 'mdi-currency-usd',
    stagingOnly: false,
  },
  {
    parent: 'Price Finder',
    title: 'Local Prices',
    path: '/dashboard/bids/base_price/',
    stagingOnly: false,
    ldFlagKey: 'local-prices',
    permission: { scope: 'prices', permission: 'read' },
  },
  {
    parent: 'Price Finder',
    title: 'Price Map',
    path: '/bids/map/',
    stagingOnly: false,
    permission: { scope: 'price_map', permission: 'read' },
    allowBadPayments: true,
  },

  {
    title: 'Videos',
    path: '/videos/',
    icon: 'mdi-youtube',
  },

  {
    title: 'Weather',
    path: '/weather/',
    icon: 'mdi-cloud',
    routerPath: false,
  },
  {
    title: 'Barchart Detail',
    path: '/dashboard/barchart/detail/',
    icon: 'mdi-book-multiple',
  },
  {
    title: 'Messenger',
    path: '/messenger/',
    icon: 'mdi-message-text',
    counter: 'chat/unreadMessagesCount',
  },

  {
    title: 'Statistics',
    icon: 'mdi-chart-line',
    disabled: true,
    isParent: true,
  },
  {
    parent: 'Statistics', title: 'Price', path: '#', disabled: true,
  },

  {
    title: 'Account',
    icon: 'mdi-domain',
    path: '/dashboard/organization/profile/',
    permission: { scope: 'profile', permission: 'read' },
  },
  { isParent: true, title: 'Salesforce', icon: 'mdi-salesforce' },
  {
    parent: 'Salesforce',
    title: 'Admin',
    path: '/salesforce/admin/',
    permission: {
      scope: 'salesforce',
      permission: 'read',
    },
  },
  {
    parent: 'Salesforce',
    title: 'Credentials',
    path: '/salesforce/credentials/',
    permission: {
      scope: 'salesforce',
      permission: 'read',
    },
  },
  {
    title: 'Farm Advisor Portal',
    icon: 'mdi-account-group',
    path: '/gma_module/',
    isGMA: true,
  },

  {
    isParent: true,
    title: 'Marketing Module',
    icon: 'mdi-account-group',
  },

  {
    parent: 'Marketing Module',
    title: 'Invitations',
    path: '/marketing_module/invitations/',
    permission: { scope: 'marketing_campaign', permission: 'create' },
    routerPath: true,
  },

  {
    parent: 'Marketing Module',
    title: 'Marketing Message',
    path: '/marketing_module/marketing_message/',
    permission: { scope: 'marketing_message', permission: 'read' },
    routerPath: true,
  },

  {
    parent: 'Marketing Module',
    title: 'Managing Content',
    path: '/marketing_module/managing_content/',
    permission: { scope: 'marketing_message', permission: 'read' },
    routerPath: true,
    ldFlagKey: 'managing-content',
  },

  {
    parent: 'Marketing Module',
    title: 'Campaign URL',
    path: '/marketing_module/campaign_url/',
    permission: { scope: 'marketing_campaign', permission: 'read' },
    routerPath: true,
  },

  {
    parent: 'Marketing Module',
    title: 'Coupons Config',
    path: '/marketing_module/coupons_config/',
    permission: { scope: 'marketing_campaign', permission: 'read' },
    routerPath: true,
    ldFlagKey: 'coupon-management',
    showOnlyPC: true,
  },

  {
    isParent: true,
    title: 'CSS Module',
    icon: 'mdi-alarm-panel',
  },

  {
    parent: 'CSS Module',
    title: 'Accounts',
    path: '/account/css_module/accounts/',
    permission: {
      scope: 'css_module',
      permission: 'read',
    },
  },

  {
    parent: 'CSS Module',
    title: 'Activation Centre',
    path: '/account/css_module/invitations/',
    permission: {
      scope: 'css_module',
      permission: 'read',
    },
  },

  {
    parent: 'CSS Module',
    title: 'Send Message',
    path: '/account/css_module/send_message/',
    permission: {
      scope: 'send_message',
      permission: 'read',
    },
  },
];
